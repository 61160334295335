import * as sessionActions from "actions/SessionActions";
import { produce } from "immer";
import jwtDecode from "jwt-decode";
import * as moment from "moment";
import { Reducer } from "redux";
import { DefaultSessionState, SessionState } from "types/SessionTypes";
import { ActionType, getType } from "typesafe-actions";

export type SessionAction = ActionType<typeof sessionActions>;

const reducer: Reducer<SessionState, SessionAction> = (
  state = DefaultSessionState,
  action: SessionAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(sessionActions.Session_Reducer_Reset): {
        return DefaultSessionState;
      }
      case getType(sessionActions.Session_Reducer_SetLoginResult): {
        const LOGINRESULT = action.payload;

        if (LOGINRESULT) {
          const TOKEN_STRING = LOGINRESULT.token;

          // ✅ Check if the token exists before decoding
          if (!TOKEN_STRING || TOKEN_STRING.trim().length === 0) {
            console.error("❌ Token is missing or empty.");
            return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["Login failed. No token received."] };
          }

          try {
            const DECODED_TOKEN: any = jwtDecode(TOKEN_STRING);

            if (!DECODED_TOKEN || !DECODED_TOKEN.exp) {
              console.error("❌ Invalid token format.");
              return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["Invalid token format."] };
            }

            const WEBNAME: string = DECODED_TOKEN.WebName;
            const WEB_ID: string = DECODED_TOKEN.WebId;
            const USER_NAME: string = DECODED_TOKEN.UserName;
            const USER_ID: string = DECODED_TOKEN.sub;
            const BACKEND_APP_VERSION: string = DECODED_TOKEN.appversion;
            const SESSION_EXPIRES = moment.unix(DECODED_TOKEN.exp).toDate();

            const ROLES_RAW: string | string[] | undefined | null = DECODED_TOKEN.roles;
            let rolesArray: string[] = [];
            let isHitchHiker = false;
            let isAdmin = false;

            if (ROLES_RAW) {
              rolesArray = typeof ROLES_RAW === "string" ? [ROLES_RAW] : ROLES_RAW;
            }

            isAdmin = rolesArray.findIndex(e => e.toLowerCase() === 'ua') !== -1;
            isHitchHiker = rolesArray.findIndex(e => e.toLowerCase() === 'hh') !== -1;

            if (!isAdmin) {
              return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["You do not have administrative access rights."] };
            }

            draft.BackendVersion = BACKEND_APP_VERSION;
            draft.WebID = WEB_ID;
            draft.ErrorMessages = [];
            draft.isLoggedIn = true;
            draft.JwtToken = TOKEN_STRING;
            draft.LoginFailed = false;
            draft.WebName = WEBNAME;
            draft.UserId = USER_ID;
            draft.UserName = USER_NAME;
            draft.SessionExpires = SESSION_EXPIRES;
            draft.IsAdmin = isAdmin;
            draft.IsHitchHiker = isHitchHiker;
          } catch (error) {
            console.error("❌ Error decoding token:", error);
            return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["Failed to process authentication token."] };
          }
        } else {
          return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["Connection to backend services failed."] };
        }
        return;
      }
      case getType(sessionActions.Session_Reducer_LoginFailed): {
        draft.LoginFailed = true;
        return;
      }
      case getType(sessionActions.Session_Reducer_SetDeployInfo): {
        draft.DeployInfo = action.payload;
        return;
      }
      case getType(sessionActions.Session_Reducer_SetLoginErrorMessages): {
        draft.ErrorMessages = action.payload;
        return;
      }
      case getType(sessionActions.Session_Reducer_PushTransactionID): {
        draft.TransactionIDs.unshift(action.payload);
        draft.TransactionIDs.splice(10, 1); // save max 10 TIDS
        draft.NotificationOpen = true;
        return;
      }
      case getType(sessionActions.Session_Reducer_SetNotificationOpen): {
        draft.NotificationOpen = action.payload;
        return;
      }
    }
  });

export { reducer as sessionReducer };

