import { Chip, Dialog, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from "@material-ui/icons/Info";
import LanguageIcon from "@material-ui/icons/Language";
import { Session_Saga_Logout, Session_Saga_RefreshToken } from "actions/SessionSagactions";
import { Environment } from 'Constants';
import React, { useState } from "react";
import * as CountDown from "react-countdown-now";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
interface NavbarUserInfoProps { }
const NavbarUserInfo: React.FC<NavbarUserInfoProps> = props => {
  const username = useSelector((state: State) => state.Session.UserName);
  const userId = useSelector((state: State) => state.Session.UserId);
  const webname = useSelector((state: State) => state.Session.WebName);
  const deployInfo = useSelector((state: State) => state.Session.DeployInfo);

  const sessionExpires = useSelector(
    (state: State) => state.Session.SessionExpires
  );
  const transactionIds = useSelector(
    (state: State) => state.Session.TransactionIDs
  );

  const dispatch = useDispatch();

  const [tidmodalopen, settidmodalopen] = useState(false);

  function RefreshSession() {
    dispatch(Session_Saga_RefreshToken());
  }
  function Logout() {
    dispatch(Session_Saga_Logout());
  }
  
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item >
          <Chip
            // style={{ background: "inherit" }}
            label={<span>Version {deployInfo?.Version} | {Environment}</span>}
            icon={<HelpIcon />}
            color="primary"
          />
        </Grid>
        <Grid item >
          <Chip
            color="primary"
            label={<span>Web: {webname}</span>}
            icon={<LanguageIcon />}
          />
        </Grid>
        <Grid item >
          <Chip
            color="primary"
            label={<span>User: {username}</span>}
            icon={<AccountCircleIcon />}
          />
        </Grid>
        {sessionExpires && (
          <Grid item>
            <Chip
              label={
                <CountDown.default
                  onComplete={() => Logout()}
                  daysInHours={true}
                  date={sessionExpires}
                />
              }
              icon={<AccessTimeIcon />}
              onClick={RefreshSession}
              color="primary"
            />
          </Grid>
        )}
        <Grid item>
          <Chip
            clickable
            color="primary"
            label={<span>TID</span>}
            icon={<InfoIcon />}
            onClick={() => {
              settidmodalopen(true);
            }}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            color="primary"
            label={<span>Logout</span>}
            icon={<ExitToAppIcon />}
            onClick={Logout}
          />
        </Grid>
      </Grid>






      <Dialog open={tidmodalopen} onClose={() => settidmodalopen(false)}>
        <DialogTitle>Transactions</DialogTitle>
        <List>
          {transactionIds.map(transaction => (
            <ListItem key={transaction.TransactionID}>
              <ListItemText primary={`${transaction.Label}`} />
              <ListItemSecondaryAction>
                <IconButton color="primary" edge="end">
                  <FileCopyIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

      </Dialog>

    </React.Fragment>

  );
};

export default NavbarUserInfo;
